<footer class="footer">
  <div class="footer__item footer__item_social">
    <div class="footer__item-item">
      <app-social type="link"></app-social>
    </div>
  </div>
  <div class="footer__item footer__item_text">
    <div class="footer__item-item footer__item-item_links">
      <div class="footer__links">
        <span class="footer__links-link">
          Trust and Safety
        </span>
        <span class="footer__links-link">
          Terms of Use
        </span>
        <span class="footer__links-link">
          Privacy Policy
        </span>
      </div>
    </div>
    <div class="footer__item-item footer__item-item_c">
      <span class="footer__c">
        Sure © 2020
      </span>
    </div>
  </div>
</footer>
