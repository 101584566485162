import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.styl']
})
export class SponsorsComponent implements OnInit {
  public items = [
    {
      tabTitle: 'Sure.',
      title: 'Sure.',
      link: `
        <a href="https://sure.so" class="c-titles__link">sure.so</a>
      `,
      about: {
        ru: `
          <p>
            Новая фандрайзинговая платформа для армянских некоммерческих организаций - Sure. Это систематизированный, эффективный и прозрачный инструмент для совершения пожертвований. Основная цель проекта - создать новую культуру индивидуальной филантропии, заменив единоразовые пожертвования более системной и последовательной поддержкой на постоянной основе. Платформа дает возможность организациям рассказывать аудитории о своих целях, миссии, достижениях и актуальных проектах, а пользователям - легко, быстро и удобно жертвовать деньги на поддержку процветания Армении.
          </p>
        `
      },
      social: {},
      person: {
        ava: '/assets/img/suren.png',
        name: 'Сурен Тер-Овсепян',
        sub_name: 'CEO, Founder',
        text: `
        <p>
          Мы – sure. Систематизированная, эффективная и прозрачная онлайн-платформа для совершения пожертвований в благотворительные фонды Армении. И сейчас – в связи с ситуацией в Арцахе – наш народ нуждается в поддержке.
        </p>
      `,
      },
    },
    {
      tabTitle: 'Disney',
      title: 'Disney',
      link: `
        <a href="https://www.disney.ru" target="_blank" class="c-titles__link">disney.ru</a>
      `,
      about: {
        ru: `
          <p>
            Уолт Дисней Компани СНГ», дочерняя компания The Walt Disney Company, была основана в апреле 2006 года. В России и странах СНГ компания развивает производство и прокат кинофильмов; сценические постановки; производство и дистрибуцию телевизионного контента, Канал Disney, лицензирование потребительских товаров — одежды, игрушек, товаров для детей, канцелярских принадлежностей, продуктов питания, косметических товаров и др.; лицензирование издательской деятельности — книг и журналов для детей; производство и дистрибуцию цифрового видео, игр для консолей, мобильных и онлайн-игр, а также развивает направление тематических парков и круизные путешествия Disney среди российских туристов.
          </p>
        `
      },
      social: [
        {
          short_name: 'fb',
          url: 'https://ru-ru.facebook.com/DisneyRussia'
        },
        {
          short_name: 'vk',
          url: 'https://vk.com/disney'
        },
        {
          short_name: 'insta',
          url: 'https://www.instagram.com/disneyrussia/?hl=ru'
        }
      ]
    },
    {
      tabTitle: 'Zapomni',
      title: 'Zapomni',
      link: `
        <a href="https://zapomni.pro" target="_blank" class="c-titles__link">zapomni.pro</a>
      `,
      about: {
        ru: `
          <p>
            Группа компаний Zapomni ведёт предпринимательскую деятельность на территории Российской Федерации в сфере культуры, кино, музыкального продюсирования, электронной коммерции, а также занимается разработкой информационных систем и их последующей интеграцией в учреждения культуры и досуга.
          </p>
        `
      },
      social: [
        {
          short_name: 'fb',
          url: 'https://www.facebook.com/zapomni.group'
        },
        {
          short_name: 'vk',
          url: 'https://vk.com/zapomnipro'
        },
        {
          short_name: 'insta',
          url: 'https://www.instagram.com/zapomni.pro'
        }
      ]
    },
    {
      tabTitle: 'АССО',
      title: 'АССО',
      // link: `
      //   <a href="https://zapomni.pro" target="_blank" class="c-titles__link">zapomni.pro</a>
      // `,
      about: {
        ru: `
          <p>
            Армянский государственный симфонический оркестр (АССО) был основан в 2005 году дирижером Сергеем Смбатяном, который также является его художественным руководителем и главным дирижером. Был основан в качестве Государственного молодежного оркестра Армении.
          </p>
          <p>
            В течение 14 лет Государственный симфонический оркестр Армении посвящал себя классической музыке. Оркестр исполняет более 50 концертов в год и имеет разносторонний репертуар, от оркестровых шедевров до ультрасовременных премьер произведений армянских и зарубежных композиторов.
          </p>
        `
      },
      social: null
    },
    {
      tabTitle: 'Гармония детства',
      title: 'Гармония детства',
      // link: `
      //   <a href="https://zapomni.pro" target="_blank" class="c-titles__link">zapomni.pro</a>
      // `,
      about: {
        ru: `
          <p>
            Благотворительный фонд создан в 2018 году. Цель фонда - создание и реализация новых форматов мероприятий для популяризации классической культуры среди детей младшего и среднего возраста.
          </p>
        `
      },
      social: null
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
