<div class="s-related">
  <div class="s-related__head">
    <h4 class="s-related__title">Организаторы</h4>
  </div>
  <div class="s-related__body">
    <app-tabs theme="2">
      <app-tabs-tab [tabTitle]="item.tabTitle" *ngFor="let item of items">
        <section class="mxn-section mxn-section_pad-1 mxn-section_theme-1">
          <div class="gifts__wrap">
            <div class="gifts__row">
              <div class="gifts__col gifts__col_text">
                <div class="c-titles">
                  <div class="c-titles__head">
                    <h3 class="c-titles__title" [innerHTML]="item.title | multilang">

                    </h3>
                  </div>
                  <div class="c-titles__body" *ngIf="item.link || item.social">
                    <div class="c-titles__body-item" *ngIf="item.link" [innerHTML]="item.link">
                    </div>
                    <div class="c-titles__body-item" *ngIf="item.social">
                      <app-social [items]="item.social"></app-social>
                    </div>
                  </div>
                </div>
              </div>
              <div class="gifts__col gifts__col_content">
                <article class="person">
                  <div class="person__head">
                    <div class="person__about" [innerHTML]="item.about | multilang">
                    </div>
                  </div>
                  <div class="person__body" *ngIf="item.person">
                    <div class="person__body-item" *ngIf="item.person.ava">
                      <div class="person__ava" [ngStyle]="{'background-image': 'url(' + item.person.ava + ')'}"></div>
                    </div>
                    <div class="person__body-item">
                      <div class="person__info">
                        <div class="person__info-head" *ngIf="item.person.name || item.person.sub_name">
                          <h5 class="person__info-title" *ngIf="item.person.name" [innerHTML]="item.person.name | multilang">

                          </h5>
                          <h6 class="person__info-title person__info-title_sub"
                              *ngIf="item.person.sub_name" [innerHTML]="item.person.sub_name | multilang">
                          </h6>
                        </div>
                        <div class="person__info-body">
                          <div class="person__about"
                               *ngIf="item.person.text"
                               [innerHTML]="item.person.text | multilang">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
      </app-tabs-tab>
    </app-tabs>
  </div>
</div>
