import { Injectable } from '@angular/core';
import {ICertificateBody, ICertificateRes, IShowcaseEvent, IShowcaseFundraising} from '../../../types';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {finalize} from 'rxjs/operators';


export interface IPartnershipInc {
  percent?: number;
}


@Injectable({
  providedIn: 'root'
})
export class CartService {
  public readonly prices_currency = {
    rur: '₽',
    rub: '₽',
    usd: '$',
    eur: '€',
    amd: '֏',
  };
  public readonly default_currency = 'rub';
  public partnershipInc: IPartnershipInc = {
    percent: 0
  };
  public oneValuePrice = 100000;
  public prices: number[];
  public currEvent: IShowcaseEvent;
  public total_cost = 0;
  public syncing = false;

  public set setterPrices(prices: number[]) {
    this.prices = prices.sort((a, b) => b - a);
  }
  public get getPrices(): number[] {
    return this.prices;
  }

  public set setterEvent(event: IShowcaseEvent) {
    this.currEvent = event;
  }
  public get getEvent(): IShowcaseEvent {
    return this.currEvent;
  }

  constructor(
    private api: ApiService
  ) { }

  public calcFundraisingPercent(data: IShowcaseFundraising): number {
    return data.total_sum / data.fundraising_plan * 100 - this.partnershipInc.percent;
  }

  public countValuesByPrice(price: number): number {
    return price / this.oneValuePrice;
  }

  public selectPrice(price: number): void {
    if (this.total_cost === price) {
      this.total_cost = 0;
    } else {
      this.total_cost = price;
    }
  }

  public changePriceCalc(dir: number): void {
    const price = this.total_cost + dir;
    this.total_cost = price >= 0 ? price : 0;
  }

  public checkout(order: ICertificateBody): Observable<ICertificateRes> {
    this.syncing = true;
    return this.api.newOrder(order).pipe(
      finalize(
        () => {
          setTimeout(() => {
            this.syncing = false;
          }, 1000);
        }
      )
    );
  }
}
