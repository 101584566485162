<div class="layout">
  <div class="layout__wrap">
<!--    <app-header></app-header>-->
    <div class="layout__content">
      <div class="layout__content-inner">
        <div class="layout__content-wrap">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <app-footer class="mxn-container mxn-container_gap-1"></app-footer>
  </div>
</div>
