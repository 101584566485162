<div class="cart-panel" *ngIf="cart.total_cost > 0">
  <div class="cart-panel__col cart-panel__col_values">
    <div class="cart-panel__values">
      <div class="cart-panel__values-item">
        <span class="cart-panel__value cart-panel__value_count">
          {{cart.total_cost | countValuesByPrice}} x
        </span>
      </div>
      <div class="cart-panel__values-item cart-panel__values-item_text"
           *ngIf="data?.meta?.fiscal_position_title | multilang">
        <span class="cart-panel__text">
          {{data.meta.fiscal_position_title | multilang}}
        </span>
      </div>
      <div class="cart-panel__values-item cart-panel__values-item_price">
        <span class="cart-panel__value">
          {{cart.total_cost | money}}
        </span>
      </div>
    </div>
  </div>
  <div class="cart-panel__col cart-panel__col_buttons">
    <div class="cart-panel__buttons">
      <button type="button"
              class="cart-panel__btn mxn-btn mxn-btn_full-width mxn-btn_ta-c mxn-btn_sz-2 mxn-btn_theme-2"
              routerLink="/checkout">
        Оплатить
      </button>
    </div>
  </div>
</div>
